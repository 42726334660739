.fadeInOut-enter {
  transform: scale(0.4);
  transform-origin: 75% 0%;
}

.fadeInOut-enter.fadeInOut-enter-active {
  transform: scale(1);
  transition: all 0.3s ease-in;
}

.fadeInOut-leave {
  transform: scale(1);
  transform-origin: 75% 0%;
  margin: 0 0;
}

.fadeInOut-leave.fadeInOut-leave-active {
  transform: scale(0);
  transition: all 0.5s ease-in;
  margin: -50 0px;
}

.Opacity-enter {
  opacity: 0.01;
}

.Opacity-enter.Opacity-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
}

.Opacity-leave {
  opacity: 1;
}

.Opacity-leave.Opacity-leave-active {
  opacity: 0.01;
  transition: all 500ms ease-in;
}
