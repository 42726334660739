input[type='text'].label-input {
  overflow: hidden;
  flex-grow: 1;
  flex: 1;
}

Grid.Column {
  vertical-align: middle;
}

.product-suggest#five {
  margin: 0;
}
