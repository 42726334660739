.Select-input {
  height: 22px !important;
}

.Select-control {
  border-radius: 0 !important;
  border: solid 0.5px #bebebe;
}

.Select--multi .Select-value {
  line-height: 1;
  height: 20px;
  margin-top: 1px;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 22px;
}

.MenuItem:hover {
  cursor: pointer;
  background: rgba(80, 80, 80, 0.98);
}
