.CustomerAddress {
  flex: 2;
  position: relative;
}

.CustomerAddress__Header--Shpping {
  display: inline-block;
  margin-right: 10px;
}

.CustomerAddress__Header {
  display: inline-block;
  margin-bottom: 8px;
}

.CustomerAddress__Selector {
  /* flex: 4; */
}

.CustomerAddress__Contents {
  margin-bottom: 0;
}

.CustomerDetail {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.ItemDetail__Header {
  margin-bottom: 8px;
}

.ItemDetail__Contents {
  margin-bottom: 0;
}

#ListHeaders.ListHeader {
  padding-left: 11px;
  padding-top: 13px;
}

.ListItems {
  padding-left: 11px;
  padding-top: 13px;
}

/* SalesOrderListItem datepicker*/
.react-datepicker-popper {
  position: fixed !important;
  z-index: 1 !important;
  top: 140px !important;
  left: unset !important;
}
#datePicker {
  width: 100%;
}
#SOModal {
  cursor: pointer;
}

/* SalesOrder PDF ----------------------------------------- */

.SalesOrderPDF__Container {
  width: 210mm;
  /* min-height: 297mm; */
  margin-left: auto;
  margin-right: auto;
  padding: 2mm;
  /* border: solid 1px black; */
  position: relative;
}

.SalesOrderPDF__Header--CompanyLogo {
  width: 240px;
  height: 55.3px;
}

.SalesOrderPDF__Header--Company {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.SalesOrderPDF__Header--SalesOrderInfo {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  text-align: right;
}

.SalesOrderPDF__Header--CompanyName {
  margin: 0;
  font-weight: 900;
}

.SalesOrderPDF__Header--SalesOrder {
  font-size: 10mm;
  font-weight: 500;
  margin-top: 5mm;
  margin-bottom: 0;
}

.SalesOrderPDF__Header--SalesOrderNumber {
  color: gray;
  margin: 0;
}

.SalesOrderPDF__Header--CompanyAddress {
  font-size: 9pt;
}

.SalesOrderPDF__Header--CompanyAddressMultiLine {
  font-size: 9pt;
  margin: 0px;
}

.SalesOrderPDF__Article {
  margin-top: 10mm;
}

.SalesOrderPDF__Article--section {
  display: inline-block;
  width: 50%;
}

.SalesOrderPDF__Article--BillToHeader {
  margin: 0;
}

strong.SalesOrderPDF__Article--Address {
  font-weight: 900;
}

.SalesOrderPDF__Article--Address {
  margin: 0;
  font-size: 9pt;
}

.SalesOrderPDF__Article section {
  /* margin-bottom: 10mm; */
}

.SalesOrderPDF__Article--ShipToContainer {
  width: 50%;
  display: inline-block;
  vertical-align: bottom;
}

.SalesOrderPDF__Article--SalesOrderTypes {
  /* float: right; */
  width: 50%;
  display: inline-block;
  vertical-align: bottom;
  float: right;
  margin-top: 20px;
}

.SalesOrderPDF__Article--SalesOrderTypesName {
  width: 50%;
  text-align: right;
  color: gray;
  font-weight: 500;
}

.SalesOrderPDF__Article--SalesOrderTypesValue {
  width: 50%;
  text-align: right;
  font-weight: 500;
}

.SalesOrderPDF__Article--SalesOrderTypesTable {
  width: 100%;
}

.SalesOrderPDF__Article--ProductTable {
  width: 100%;
  text-align: right;
  border-collapse: collapse;
}

.SalesOrderPDF__Article--ProductTable thead th {
  background: #3c3d3a;
  padding: 1.5mm;
  font-weight: 100;
  color: white;
  font-size: 9.5pt;
}

.SalesOrderPDF__Article--ProductTable tbody tr {
  border-bottom: solid 1px #f2f2f2;
  height: 15mm;
}

.SalesOrderPDF__Article--ProductTable th:nth-child(1) {
  width: 10mm;
  text-align: center;
}

.SalesOrderPDF__Article--ProductTable td:nth-child(1) {
  text-align: center;
}

.SalesOrderPDF__Article--ProductTable th:nth-child(2) {
  text-align: left;
}

.SalesOrderPDF__Article--ProductTable td:nth-child(2) {
  text-align: left;
}

.SalesOrderPDF__Article--ProductTable td:nth-child(5) {
  text-align: right;
}

.SalesOrderPDF__Article--ProductTable th:nth-child(4),
.SalesOrderPDF__Article--ProductTable th:nth-child(5),
.SalesOrderPDF__Article--ProductTable th:nth-child(6) {
  width: 25mm;
}

.SalesOrderPDF__Article--ProductName {
  font-weight: 700;
  font-size: 9.5pt;
  margin: 0;
}

.SalesOrderPDF__Article--ProductDescription {
  font-size: 8pt;
  margin: 0;
}

.SalesOrderPDF__Article--Total {
  margin-bottom: 33mm;
}

.SalesOrderPDF__Article--TotalTable {
  width: 50%;
  float: right;
  text-align: right;
  border-collapse: collapse;
  margin-top: 10px;
}

.SalesOrderPDF__Article--TotalTable tr {
  height: 10mm;
}

.SalesOrderPDF__Article--TotalTable tr td {
  width: 25mm;
}

.SalesOrderPDF__Article--TotalTableTotal {
  background: #f2f2f2;
}

.SalesOrderPDF__Article--ListPriceTotal {
  font-size: 9pt;
}

.SalesOrderPDF__Article--AmountTotal {
  font-size: 9pt;
}

.CancelButton {
  width: 100%;
}

.CustomerAddress__edit--button,
.CustomerAddress__add--button,
.CustomerAddress__delete--button {
  margin: 0 10px;
  display: inline-block;
}

.CustomerAddress__edit--button:hover,
.CustomerAddress__add--button:hover,
.CustomerAddress__delete--button:hover {
  cursor: pointer;
}

.CustomerAddress__Edit--Container {
  position: absolute;
  background: white;
  border: solid 1px #c7c7c7;
  z-index: 1;
  width: 230px;
  transform: translateX(66%);
  border-radius: 3px;
  box-shadow:
    0 30px 90px -20px rgba(0, 0, 0, 0.2),
    0 0 1px #a2a9b1;
  max-height: 500px;
  overflow: hidden;
  top: 0;
}

.CustomerAddress__Edit--Scroller {
  max-height: 470px;
  overflow: auto;
}

.CustomerAddress__Contents--Container {
  padding: 15px 10px;
}

.CustomerAddress__Contents--Container:hover {
  cursor: pointer;
}

.CustomerAddress__Contents--Container:nth-child(even) {
  background: #f8f8f8;
}

.CustomerAddress__Contents.CustomerAddress__Contents--Inactive {
  opacity: 0.3;
}

.CustomerAddress__AccountName {
  font-weight: 600;
}

.product__selector {
  border-radius: 0 !important;
  border-right: 0 !important;
}

.product__selector:nth-child(1) {
  border-radius: 5px 0 0 5px !important;
}

.product__selector:nth-child(5) {
  border-radius: 0 5px 5px 0 !important;
  border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.SalesOrderPDF__BackAccountContainer p {
  margin: 0;
}

.DropShipContact__Edit--Container {
  position: absolute;
  background: white;
  border: solid 1px #c7c7c7;
  z-index: 1;
  transform: translateX(66%);
  border-radius: 3px;
  box-shadow:
    0 30px 90px -20px rgba(0, 0, 0, 0.2),
    0 0 1px #a2a9b1;
  overflow: hidden;
  top: 20px;
}

.DropShipContact__Edit--Scroller {
  max-height: 470px;
  overflow: auto;
}
