.Navigation-root {
  height: 100vh;
  width: 100vw;
}

.Page-container {
  background: #fff;
  height: 100vh;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: auto;
}
