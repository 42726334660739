.c011 > .c013 {
  padding: 1.2rem;
  position: relative;
  max-width: 90% !important;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  background-clip: padding-box;
}

.c011 > .c013 > .ui.container {
  width: 100% !important;
}

/* .Modal-modal-0-1-3  > div > .ui.container {
    width: 100% !important;
  } */

.Modal-modal-0-1-3 {
  padding: 1.2rem;
  position: relative;
  max-width: 90% !important;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  background-clip: padding-box;
}

.Modal-modal-0-1-3 > .ui.container {
  width: 100% !important;
}
