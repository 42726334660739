.MenuBar {
  height: 100vh;
  width: 320px;
  overflow: hidden;
  position: absolute;
  transition: width 0.25s ease-in-out;
  z-index: 1;
}

.MenuBar.open {
  width: 320px;
}

.MenuBar.fold {
  width: 48px;
}

.MenuBar__Header {
  height: 48px;
  width: 48px;
}

.MenuBar__Header:hover {
  background: rgba(255, 255, 255, 0.2);
}

.MenuBar__Menu {
  width: 320px;
  height: 48px;
  max-height: 48px;
}

.MenuBar__Menu:hover {
  background: rgba(255, 255, 255, 0.2);
}

.MenuBar__Menu--icon {
  display: inline-block;
  padding: 13px 13px;
  height: 48px;
  width: 48px;
}

.MenuBar__Menu--icon * {
  max-width: 22px;
  max-height: 22px;
}

.MenuBar__Menu--name {
  display: inline-block;
  vertical-align: top;
  height: 48px;
  width: 100px;
}

.MenuBar__Menu--name > span {
  color: white;
  line-height: 48px;
}

.MenuBar__Menu.MenuBar__Menu--selected {
  background: rgba(255, 255, 255, 0.2);
}

.MenuBar__background {
  width: 320px;
  height: 100vh;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}

.Menu__background--image {
  width: 100%;
  height: 100%;
  filter: blur(25px) brightness(0.7);
  transform: scale(1.2);
}

.MenuBar__Menu--highlighter {
  height: 48px;
  width: 4px;
  background: white;
  position: absolute;
  /* border-radius: 0 4px 4px 0; */
  transition: 0.3s ease;
  top: 48px;
  left: 0;
}

.MenuBar__Menu--name#User {
  width: 250px;
}
.MenuBar__Menu--name#Company {
  height: 48px;
  width: 250px;
}
.MenuBar__Menu--name#Company_ {
  padding-left: 14px;
  height: 48px;
  width: 48px;
}
.CompanyDropDown#Company {
  position: absolute;
  font-size: 15px;
  bottom: 15px;
  color: white;
}
