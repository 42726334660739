.clickable-span {
  color: #369aff;
  margin-left: 10px;
  text-decoration: underline;
  opacity: 1;
  transition: all 0.5s ease;
}

.clickable-span::before {
  opacity: 0;
}

.clickable-span::after {
  opacity: 1;
}

.clickable-span:hover {
  cursor: pointer;
}
